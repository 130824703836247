
/**
 * @name mapColdModules 获取多个冷数据模型
 * @param {*} moduleNames []
 * @returns
 */
export const mapColdModules = (storeInstance, moduleNames = []) => {
  const res = {}
  const coldModules = storeInstance.state.newProductDetail.coldModules
  moduleNames?.forEach((moduleName) => {
    res[moduleName] = coldModules[moduleName]
  })
  return res
}


/**
 * @name getComponentsGetter 获取对应组件的getters
 * @param {*} namespaced 如: 'GoodsName'...
 * @param {*} getter  如: 'goodsName'...
 * @returns {}
 */
export const getComponentsGetter = (storeInstance, namespaced, getter = '') => {
  return storeInstance.getters[`newProductDetail/${namespaced}/${getter}`]
}


/**
 * @name mapComponentsGetters 获取对应组件的getters
 * @param {*} namespaced 如 'GoodsName'...
 * @param {*} getters string[] 如: ['goodsName', 'isBlack']...
 * @returns {}
 */
export const mapComponentsGetters = (storeInstance, namespaced, getters) => {
  const res = {}
  getters?.forEach((getter) => {
    const getterName = `newProductDetail/${namespaced}/${getter}`
    const getterValue = storeInstance.getters[getterName]
    res[getter] = getterValue
  })
  return res
}

/**
 * @name mapRootGetters 获取对应的getters
 * @param {*} namespaced 如 'GoodsName'...
 * @param {*} getters string[] 如: ['goodsName', 'isBlack']...
 * @returns {}
 */
export const mapRootGetters = (rootGetters, namespaced, getters) => {
  const res = {}
  getters?.forEach((getter) => {
    const getterName = `newProductDetail${namespaced ? '/' + namespaced : '' }/${getter}`
    const getterValue = rootGetters[getterName]
    res[getter] = getterValue
  })
  return res
}
