/**
 * @field 推荐商卡基本数据
 */
export default {
  // 简易价格组件依赖数据
  base_price_simple_component_data(state, getters, rootState, rootGetters) {
    const isHitComplianceMode = rootGetters['productDetail/common/is_hit_compliance_mode']
    const useBlackColorPrice = rootGetters['productDetail/common/compliance_tips_mode'] || rootGetters['productDetail/common/compliance_mode_de']

    const language = rootGetters['productDetail/common/language_v2']
    const useSuggestedPriceComponent = isHitComplianceMode && !useBlackColorPrice
    const isPaid = rootGetters['productDetail/common/is_paid_user']

    return {
      useSuggestedPriceComponent,
      useBlackColorPrice,
      language,
      isPaid,
    }
  },
  /**
       * 商卡客户端预处理bff依赖数据
       */
  product_bff_depend_data(state, getters, rootState, rootGetters) {
    const product_items_language = rootGetters['productDetail/common/product_items_language']
    const isPaid = !!rootGetters['productDetail/common/is_paid_user']
    const compliance_mode = rootGetters['productDetail/common/compliance_mode']
    const compliance_tips_mode = rootGetters['productDetail/common/compliance_tips_mode']
    const compliance_mode_de = rootGetters['productDetail/common/compliance_mode_de']
  
    // 当前站点合规配置 'normal', 'special','special_de'
    const suggestedSaleType = (() => {
      if (compliance_mode_de) return 'special_de'
      if (compliance_tips_mode) return 'special'
      if (compliance_mode) return 'normal'
    })()
  
    const lang = (typeof gbCommonInfo !== 'undefined' && gbCommonInfo.lang) || 'us'
  
    return {
      lang,
      language: product_items_language,
      isPaid,
      suggestedSaleType,
    }
  },
}

