// TODO MOCK

import commonTools from 'public/src/pages/product_app/store/modules/product_detail/common/modules/tools.js'
import commonStock from 'public/src/pages/product_app/store/modules/product_detail/common/modules/stock.js'
import commonSaleAttr from 'public/src/pages/product_app/store/modules/product_detail/common/modules/saleAttr.js'
import commonLabel from 'public/src/pages/product_app/store/modules/product_detail/common/modules/label.js'
import commonRecommend from 'public/src/pages/product_app/store/modules/product_detail/common/modules/recommend.js'
import commonSeo from 'public/src/pages/product_app/store/modules/product_detail/common/modules/seo.js'
import { closestRatio, patchImgUrlSuffix } from 'public/src/pages/goods_detail_v2/utils/common.js'
import { reg } from 'public/src/pages/product_app/store/modules/product_detail/utils/getPrefetchParams.js'
import { ESTIMATED_DATA_TYPES } from 'public/src/pages/product_app/store/modules/product_detail/utils/price.js'

export default {
  ...commonTools,
  ...commonStock,
  ...commonSaleAttr,
  ...commonLabel,
  ...commonRecommend,
  ...commonSeo,
  base_info(state, getters, rootState, rootGetters) { // 逐步丰富替代旧数据流 detail
    const { business_model, goods_id, goods_sn, goods_img, goods_thumb, cat_id, goods_name, main_sale_attribute_list, product_relation_id } = getters
    const goods_url_name = main_sale_attribute_list?.[0]?.goods_url_name || ''
    const relation_is_sold_out = main_sale_attribute_list?.every(item => item?.isSoldOutStatus === '1')
    const price_info = rootGetters['productDetail/price_info']
    const { salePrice = {}, retailPrice = {}, unitDiscount = '' } = price_info || {}
    return {
      business_model,
      cat_id,
      goods_id,
      goods_sn,
      goods_img,
      goods_name,
      goods_thumb,
      goods_url_name,
      product_relation_id,
      unit_discount: unitDiscount,
      relation_is_sold_out,
      salePrice,
      retailPrice
    }
  },
  business_model(state, getters, rootState) {
    return rootState.productDetail.coldModules?.storeInfo?.business_model || ''
  },
  is_ssr(state, getters, rootState, rootGetters) {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return rootState.productDetail.hybridModules?.is_ssr

    return rootGetters['newProductDetail/isSsr']
  },
  is_new_size_local(state, getters, rootState, rootGetters) {
    return rootGetters['newProductDetail/common/isNewSizeLocal']
  },
  common_page_info(state, getters, rootState) {
    return rootState.productDetail.coldModules.commonPageInfo || {}
  },
  language_v2(state, getters, rootState, rootGetters) {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return rootState.productDetail.hybridModules.pageInfo.language

    return rootGetters['newProductDetail/common/language']
  },
  product_items_language(state, getters, rootState, rootGetters) { // todo_del
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return getters.language_v2

    return rootGetters['newProductDetail/common/productItemsLanguage']
  },
  cache_screen_data(state, getters, rootState, rootGetters) {
    // TODO: 旧数据流，预取数据
    const cacheScreenData = rootGetters['newProductDetail/cacheScreenData']
    return cacheScreenData
  },
  goods_name(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo.goods_name || ''
  },
  goods_id(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo.goods_id || ''
  },
  related_local_goods_id(state, getters, rootState) {
    if (!rootState.productDetail.coldModules.productInfo?.related_local_goods?.best_source_flag) return ''
    return rootState.productDetail.coldModules.productInfo?.related_local_goods?.goods_id  || ''
  },
  related_local_goods_sn(state, getters, rootState) {
    if (!rootState.productDetail.coldModules.productInfo?.related_local_goods?.best_source_flag) return ''
    return rootState.productDetail.coldModules.productInfo?.related_local_goods?.skc_name || ''
  },
  goods_thumb(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo.goods_thumb || ''
  },
  goods_img(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo.goods_img || ''
  },
  cate_name(state, getters, rootState) {
    const { cateInfos, cat_id } = rootState.productDetail.coldModules.productInfo || {}
    return cateInfos?.[cat_id]?.category_name || ''
  },
  mall_code(state, getters, rootState) {
    // 旧数据流，等依赖相关方全部迁移完毕后删除
    if (getters.cache_screen_data) {
      return getters.cache_screen_data.mallCode || ''
    }
    return rootState.newProductDetail?.SizeBox?.switchMallCode || rootState.productDetail.coldModules?.productInfo?.selectedMallCode || '' 
  },
  /** 默认mallCode */
  default_mall_code(state, getters, rootState) {
    return rootState.productDetail.coldModules?.productInfo?.selectedMallCode || '' 
  },
  // 当前mall
  current_mall(state, getters, rootState, rootGetters) {
    // 旧数据流，等依赖相关方全部迁移完毕后删除
    return rootGetters['newProductDetail/common/currentMall']
  },
  // 是否展示悬浮购物车图标
  show_float_cart_icon(_, getters) {
    return getters.show_normal_float_cart_icon || getters.show_default_float_cart_icon
  },

  // 展示购物车悬浮购物车图标（默认有标签信息的)
  show_normal_float_cart_icon(_, __, ___, rootGetters) {
    const fs_abt = rootGetters['productDetail/common/fs_abt']
    return fs_abt?.hovercart?.p?.hovercart === 'show'
  },

  // 是否展示购物车悬浮购物车图标（默认无标签信息的）
  show_default_float_cart_icon(_, __, ___, rootGetters) {
    const fs_abt = rootGetters['productDetail/common/fs_abt']
    return fs_abt?.hovercart?.p?.hovercart === 'showdefault'
  },

  // 悬浮购物车支持展开横向拉通态
  show_float_cart_icon_longpull(_, __, ___, rootGetters) {
    const fs_abt = rootGetters['productDetail/common/fs_abt']
    return fs_abt?.hovercart?.p?.longpull === 'show'
  },

  // 是否展示顶部购物车图标
  show_top_cart_icon(_, __, ___, rootGetters) {
    const fs_abt = rootGetters['productDetail/common/fs_abt']
    return fs_abt?.hovercart?.p?.oldcart !== 'none'
  },
  // 是否展示低库存加车提示
  show_low_stock_add_cart_tips(state, getters, rootState, rootGetters) {
    const fs_abt = rootGetters['productDetail/common/fs_abt']
    return fs_abt?.outofstocktips?.param?.addtocarttoastlowstocktips === 'on'
  },
  cart_config(state, getters, rootState, rootGetters) {
    const fs_abt = rootGetters['productDetail/common/fs_abt']
    const { adddynamic, collectandaddcart } = fs_abt?.collectandaddcart?.p || {}
    return {
      cartPosition: collectandaddcart === 'A' ? 'bottom' : 'top',
      cartDynamicStyle: adddynamic === 'A' ? 'img' : 'reddot'
    }
  },
  fs_abt(state, getters, rootState, rootGetters) {
    const fsAbtInfo = rootState.productDetail?.asyncModules?.fsAbtInfo || {}
    if (Object.keys(fsAbtInfo)?.length > 10) {
      return fsAbtInfo
    }
    // TODO: 后面要删掉的
    const oldRealAbtInfo = rootState.productDetail.hotModules.webProductDetailAbtInfo || {}
    if (Object.keys(oldRealAbtInfo)?.length > 0) {
      console.error('fsAbtInfo: abt数据异常')
    }
    return oldRealAbtInfo || {}
  },
  is_paid_user(state, getters, rootState, rootGetters) {
    return rootGetters['productDetail/user_info']?.isPaidMember === '1'
  },
  is_can_trial(state, getters, rootState, rootGetters) {
    return rootGetters['productDetail/user_info']?.isCanTrial == '1'
  },
  goods_sn(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo.goods_sn // @alextodotest
  },
  /**
   * 合规价相关 todo
   */
  compliance_mode(state, getters, rootState, rootGetters) { // 是否开启价格合规 / 后续新增合规规则 complianceModeV1 / complianceModeV2
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (!is_use_bff_fs) return rootState.newProductDetail.coldModules.pageInfo.complianceMode

    const { suggested_sale_price } = rootState.productDetail.hybridModules.pageInfo || {}
    return ['normal', 'special'].includes(suggested_sale_price)
  },
  compliance_tips_mode(state, getters, rootState, rootGetters)  { // 合规文案模式
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (!is_use_bff_fs) return rootState.newProductDetail.coldModules.pageInfo.complianceTipsMode

    const { suggested_sale_price } = rootState.productDetail.hybridModules.pageInfo || {}
    return suggested_sale_price === 'special'
  },
  compliance_mode_de(state, getters, rootState, rootGetters)  { // 德国合规
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (!is_use_bff_fs) return rootState.newProductDetail.coldModules.pageInfo.complianceModeDe

    const { suggested_sale_price } = rootState.productDetail.hybridModules.pageInfo || {}
    return suggested_sale_price === 'special_de'
  },
  is_hit_compliance_mode(state, getters, rootState, rootGetters) { // 是否命中合规模式，包含所有
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (!is_use_bff_fs) return rootState.newProductDetail.coldModules.pageInfo.isHitComplianceMode

    return getters.compliance_mode || getters.compliance_tips_mode || getters.compliance_mode_de
  },
  // 保留商品配置信息
  retention_position_config(state, getters, rootState) {
    const { is_sold_out, fs_abt } = getters
    const { SameLabel } = fs_abt
    const { sameLabelBelt } = rootState.productDetail.hotModules.beltInfo

    const res = {
      show: false,
      position: '',
      // rightText: '',
      contentText: '',
    }

    const position = SameLabel?.param?.SameLabel == 'NO' ? '' : SameLabel?.param?.SameLabel

    if (is_sold_out || !sameLabelBelt || !position) return res

    return {
      ...res,
      position,
      contentText: sameLabelBelt?.lowestPriceTips,
      show: true,
    }
  },
  // parent_cats(state, getters, rootState, rootGetters) {
  //   const parentCats = rootGetters['newProductDetail/common/parentCats']
  //   return parentCats
  // },
  sku_code(state, getters, rootState) {
    const { skuInfo } = rootState.newProductDetail.common || {} // TODO 这个还是旧的
    const { skc_sale_attr = [], sku_list = [] } = rootState.productDetail.coldModules?.saleAttr?.multiLevelSaleAttribute || {}
    if (skc_sale_attr?.length) return skuInfo?.sku_code || ''
    return sku_list?.[0]?.sku_code || ''
  },
  cache_skc_sale_attr(state, getters, rootState) {
    return rootState.productDetail.coldModules?.saleAttr?.multiLevelSaleAttribute?.skc_sale_attr
  },
  cache_sku_list(state, getters, rootState) {
    return rootState.productDetail.coldModules?.saleAttr?.multiLevelSaleAttribute?.sku_list
  },
  real_time_sku_list(state, getters, rootState) {
    return rootState.productDetail.hotModules?.saleAttr?.multiLevelSaleAttribute?.sku_list
  },
  sku_info(state, getters) {
    const { sku_code, cache_sku_list, real_time_sku_list } = getters || {}
    const cacheSkuInfo = cache_sku_list?.find?.(item => item.sku_code === sku_code) || {}
    const realTimeSkuInfo = real_time_sku_list?.find?.(item => item.sku_code === sku_code) || {}
    const skuInfo = {
      ...cacheSkuInfo,
      ...realTimeSkuInfo,
    }
    if (skuInfo?.sku_code) {
      return skuInfo
    }
    return null
  },
  sku_list(state, getters, rootState, rootGetters) {
    // 尽量别用了，有缓存的
    return rootGetters['productDetail/sku_list'] || []
  },
  current_local_country(state, getters, rootState) {
    // todo: 旧数据流
    const { currentLocalCountry } = rootState['newProductDetail'].common || {}
    return currentLocalCountry
  },
  show_evolu_shein(state, getters) {
    const { fs_abt } = getters
    const showEvoluShein = fs_abt?.evolushein?.p?.evolushein === 'show'
    return showEvoluShein
  },
  quick_ship(state, getters, rootState) {
    return rootState.newProductDetail.common.quickShip
  },
  product_relation_id(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo?.productRelationID || ''
    // return rootGetters['newProductDetail/common/productRelationID'] // TODO：就
  },
  cat_id(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo.cat_id
  },
  cate_ids(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo.cateIds
  },
  cate_infos(state, getters, rootState) {
    const { cateInfos = {} } =  rootState.productDetail.coldModules.productInfo || {}

    const { cate_ids = '' } = getters
    
    if(!cate_ids) return Object.values(cateInfos)
    // 根据cateIds排序
    const cateInfoList = []
    const cateIdsList = cate_ids.split(',')
    while (cateIdsList.length) {
      cateInfoList.push(cateInfos[cateIdsList.pop()])
    }

    return cateInfoList || {}
  },
  // 店铺人气氛围列表
  store_atmosphere_info(state, getters, rootState) {
    // 降级措施: bff的get_goods_detail_realtime_data接口请求取不到数据时，或降级未调用bff接口时。
    // 则数据默认为空数组。店铺人气氛围功能就不展示了。
    const { productLabel } = rootState.productDetail.hotModules
    return productLabel?.storeTipList || []
  },
  has_fit(state, getters, rootState) {
    const { comments_overview } = rootState.productDetail.hotModules.comment || {}
    return +comments_overview?.hasFit
  },
  // 是否是定制商品
  is_customization(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo?.customization_attributes?.customization_flag === 1
  },
  is_customization_new_link(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo?.customization_attributes?.customization_platfrom_flag == 1
  },
  is_satisfied_buy_multiple(state, getters, rootState, rootGetters) {
    const estimated_info = rootGetters['productDetail/price/estimated_info']
    return estimated_info?.dataType === ESTIMATED_DATA_TYPES.N_ESTIMATED
  },
  // 是否展示Quantity
  show_quantity(state, getters, rootState, rootGetters) {
    const abtInfo = rootGetters['productDetail/product_detail_abt_info']
    const sizechartshow = abtInfo?.sizechartshow?.param?.sizechartshow || ''
    const isCccTemplate = !['A', 'B'].includes(sizechartshow)
    const { productInfo } = rootState.productDetail.hotModules || {}
    const { cccDetailsTemplate } = productInfo || {}
    const { content } = cccDetailsTemplate || {}
    return getters.is_satisfied_buy_multiple || sizechartshow === 'B' || (isCccTemplate && content?.[0]?.countCell == '1')
  },
  store_code(state, getters, rootState) {
    return rootState.productDetail.coldModules.storeInfo?.store_code
  },
  store_info(state, getters, rootState) {
    return rootState.productDetail.coldModules.storeInfo
  },
  merchant_service_config(state, getters, rootState, rootGetters) {
    // 商家入口与未读数配置，只需要判断对象是否为空，非空即展示商家入口，然后读取里面的未读数字段
    const { store_code, business_model: business } = rootGetters['productDetail/common/store_info'] || {}
    const skc = rootGetters['productDetail/common/goods_sn']
    const storeInfo = rootState.productDetail?.hotModules?.storeInfo
    if(storeInfo && storeInfo.sellerServiceInfo){
      const num = parseInt(storeInfo.sellerServiceInfo.storeUnReadMsgTotal || 0)
      return { show: true, num, store_code, skc, business }
    }
    return { show: false, num: 0, store_code, skc, business }
  },
  seller_info_tips(state, getters, rootState) {
    return rootState.productDetail.hotModules.storeInfo?.sellerInfoTips
  },
  has_detail_store_seller_info(state, getters, rootState) {
    return !!rootState.productDetail.hotModules.storeInfo?.isHaveSellerInfo
  },
  label_list(state, getters, rootState) {
    return rootState.productDetail.coldModules.storeInfo?.storeLabels || []
  },
  show_from(state, getters, rootState, rootGetters) {
    return rootGetters['newProductDetail/common/showFrom']
  },
  add_to_bag_form(state, getters, rootState, rootGetters) {
    return rootGetters['newProductDetail/common/addToBagForm']
  },
  external_size_info_index(state, getters, rootState, rootGetters) {
    return rootGetters['newProductDetail/common/externalSizeInfoIndex']
  },
  // 人气氛围配置(主图 ｜ 大图 ｜ 加车按钮)
  atmosphere_config(state, getters) {
    const { fs_abt } = getters
    const { hotnews: hotnewsAbt } = fs_abt
    // 根据abt配置是否展示人气氛围(wiki/pageId=1148323611)
    const { whereshow, toastcolor, hotnews } = hotnewsAbt?.param || {}
    // 根据访问次数判断是否展示人气氛围(wiki/pageId=1321240725) --默认不限制
    let forbidShow = getters.forbid_atmosphere_flow
    // 全部禁止展示
    if (forbidShow || !hotnews) return {}

    return {
      mainPicShow: whereshow !== 'onlyaddtobag',                      
      bigPicShow: ['bigpicture', 'andaddtobag'].includes(whereshow), 
      addBagShow: ['onlyaddtobag', 'andaddtobag'].includes(whereshow),
      config: {
        color: toastcolor || ''
      }
    }
  },
  // 人气氛围数据(主图 ｜ 大图 ｜ 加车按钮)
  atmosphere_data(state, getters, rootState) {
    let { hotNews, carHotNews } = rootState.productDetail.hotModules.productLabel || {}
    const { current_mall } = getters
    const { is_absolute_low_inventory, is_relatively_low_inventory } = current_mall || {}

    let labelType = ''
    if (is_absolute_low_inventory === true) {
      labelType = 1
    }
    if (is_relatively_low_inventory === true) {
      labelType = 2
    }

    return {
      hotNews,
      carHotNews,
      labelType
    }
  },
  // 榜单相关
  ranking_list_info(state, getters, rootState) {
    const cache_screen_data = getters.cache_screen_data
    if (cache_screen_data) {
      const { rankingList } = cache_screen_data
      return {
        ...rankingList,
        // 兼容列表字段
        rankingTypeText: rankingList?.rankingTypeText || rankingList?.rankTypeText,
      }
    }

    const { toprated } = getters.fs_abt || {}
    let topRanking = rootState.productDetail.asyncModules.topRanking || {}
    topRanking.carrierSubType = topRanking?.mobileIdentifier
    topRanking.title = topRanking?.rankingTypeText + ' ' + topRanking?.composeIdText 
    topRanking.cateIds = getters.cat_id
    
    if (toprated?.param?.toprated == 'noshow' && topRanking.carrierSubType == 16) {
      return null
    }

    return topRanking ?? null
  },
  // 免费退货 TODO
  free_return_policy(state, getters, rootState, rootGetters) {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return Number(rootState.productDetail.hotModules?.productLabel?.goodsImgBenefitInfo?.isFreeReturn) === 1

    return !!(rootState.newProductDetail.hotModules?.shipping?.freeReturnPolicy * 1)
  },
  // 商品主图
  product_imgs(state, getters, rootState, rootGetters) {
    const { second_info_ready } = state
    // 商品 skc ｜ sku ｜ spu 图片 
    const { skcImages = [], skuImages = {}, spuImages = [], maskLayer = {} } = rootState.productDetail.coldModules.productInfo.currentSkcImgInfo || {}
    const { sheglamTspFlag = 1 } = rootState.productDetail.coldModules.productInfo || {}
    const sku_list = rootState.productDetail.coldModules?.saleAttr?.multiLevelSaleAttribute?.sku_list || []
    const allColorDetailImages = rootState.productDetail.coldModules.productInfo.allColorDetailImages || {}
    // 视频的位置
    const videoSort = rootState.productDetail.coldModules.productInfo.videoSort || 1
    const has_vimeo_url = rootGetters['productDetail/Main/has_vimeo_url']
    const has_video_url = rootGetters['productDetail/Main/has_video_url']
    const _hasVideo = has_vimeo_url || has_video_url
    // 商品 ID
    const goods_id = getters.goods_id
    // 商品图片排序 spu图-skc图-sku图-细节图
    const imageArr = []
    // 正则表达式
    const regex = /\/([^\/]*)_thumbnail/
    const fsAbt = getters.fs_abt
    const showSpuPic = fsAbt?.detailSpuPic?.param?.spuPic === 'show'
    let urlGoodsId
    if (typeof window !== 'undefined') {
      const paths = window.location.pathname.match(reg)
      urlGoodsId = paths instanceof Array && paths[1] ? paths[1] : null
    }
    // 为每个对象生成唯一的 ID
    const getImgID = (image) => {
      // 兼容图片后缀没有thumbnail导致的key获取失败
      if (image?.indexOf('_thumbnail') === -1) {
        image = image?.replace('.webp', '_thumbnail_720x.webp')
      }
      // 使用正则表达式执行匹配
      const match = regex.exec(image)
      const imgId = match?.[1] || Math.random().toString(36).substr(2, 9)
      return `${imgId}_${urlGoodsId || goods_id}_imgId`
    }

    // spu 图片处理
    if (showSpuPic && spuImages.length) {
      spuImages?.forEach(spu => {
        spu && imageArr.push({
          origin_image: spu,
          img_id: getImgID(spu),
          is_spu_image: true,
        })
      })
    }

    // skc 图片处理
    if (skcImages?.length) {
      skcImages?.forEach(skc => {
        skc && imageArr.push({
          origin_image: skc,
          img_id: getImgID(skc),
          is_skc_image: true,
        })
      })
    }

    // spu 图片数量 > 1时候 取spu 下面所有skc
    if (showSpuPic && spuImages?.length > 1) {
      Object.keys(allColorDetailImages).forEach(skcKey => {
        const skcImageArr = allColorDetailImages?.[skcKey] || null
        const skcImage = skcImageArr?.[0]?.origin_image || null
        if (skcImage && skcKey !== goods_id ) {
          imageArr.push({
            origin_image: skcImage,
            img_id: getImgID(skcImage),
            is_relate_skc_image: true,
            is_skc_image: true,
          })
        }
      })
    }

    // sku 图片处理 以 sku_list 顺序为准排序
    if (skuImages && !!sku_list?.length) {
      sku_list?.forEach(item => {
        const skuImage = skuImages[item.sku_code] || null
        skuImage && imageArr.push({
          origin_image: skuImage,
          img_id: getImgID(skuImage),
          sku_code: item.sku_code,
          is_sku_image: true,
        })
      })
    }

    const showSheglamWatermark = imageArr.length > 1 // 除首图外
      && fsAbt.sheglammaster?.p?.sheglammaster === 'show' // 满足实验
      && sheglamTspFlag == 1 // 满足tsp

    // 除首图/视频/GIF外/评论图、尺码图、属性图的每一张主图都需展示水印
    if (showSheglamWatermark) {
      const _START_INDEX = _hasVideo && videoSort === 1 ? 2 : 1 // 视频在首位，主图在第二张从第三张开始打水印 or 从第二张开始
      for (let i = _START_INDEX; i < imageArr.length; i++) {
        imageArr[i].origin_image = patchImgUrlSuffix(imageArr[i].origin_image, '_wk_sheglam')
        imageArr[i]._wk_dynamics = 1 // 动态水印
      }
    }

    // 是否展示细节图配置
    if (second_info_ready) {
      const { content = [] } = rootState.productDetail.hotModules.productInfo.cccDetailsTemplate || {}
      const abtInfo = rootGetters['productDetail/product_detail_abt_info']
      const moredetails = abtInfo?.moredetails?.param?.moredetails || ''
      const isCccTemplate = !['A', 'B', 'C'].includes(moredetails)
      const isShowDetail = moredetails === 'B' || (isCccTemplate && content?.[0]?.detailImg && content?.[0]?.detailImg === '1')
      // 细节图
      const detailImages = rootState.productDetail.asyncModules.currentSkcImgInfo.detailImage || []
      if (isShowDetail && detailImages?.length) {
        detailImages?.forEach(detail => {
          const image_url = detail?.image_url || null
          image_url && imageArr.push({
            origin_image: image_url,
            img_id: getImgID(image_url),
          })
        })
      }
    }

    // 取缓存图片
    const cache_goods_imgs = rootState.productDetail.common.cache_goods_imgs

    if (!!cache_goods_imgs?.bf_first_image?.bfCache) {
      // 是否同一个商品
      const isCurGoods = goods_id === urlGoodsId
      
      const bf_first_image = cache_goods_imgs?.bf_first_image
      // 替换图片
      const origin_image = isCurGoods && imageArr[0]?.origin_image
        ? imageArr[0]?.origin_image
        : bf_first_image?.bfCache

      imageArr[0] = {
        is_skc_image: imageArr[0]?.is_skc_image,
        is_spu_image: imageArr[0]?.is_spu_image,
        bfCache: bf_first_image?.bfCache,
        origin_image,
        img_id: getImgID(origin_image),
      }
    }

    // 添加主图Beauty品类蒙版 （SPU图/SKC主图）
    if (!!maskLayer?.src && imageArr?.length > 0) {
      let imgRatio = ''
      try {
        const { width, height } = maskLayer || {}
        imgRatio = (width && height) ? closestRatio(+width / +height) : null
      } finally {
        let maskIndex = 0
        // 首图有视频按钮是蒙层放在第二个
        if (has_vimeo_url || has_video_url) {
          maskIndex = +videoSort === 1 ? 1 : 0
        }
        if ((imageArr[maskIndex]?.is_skc_image || imageArr[maskIndex]?.is_spu_image)) {
          imageArr[maskIndex].maskLayer = {
            src: maskLayer.src,
            imgRatio,
          }
        }
      }
    }
    
    return imageArr
  },
  // 新搭配数据
  new_companion_info(state, getters, rootState, rootGetters) {
    const product_imgs = getters.product_imgs
    const newCompanionInfo = rootState.productDetail.asyncModules.newCompanionInfo || {}
    const { productRecommendDetailLabel } = newCompanionInfo

    // const { fs_abt } = getters
    const abtInfo = rootGetters['productDetail/product_detail_abt_info']

    const { newoutfit } = abtInfo?.newoutfit?.param || 0
    if(!['new', 'addbaginymal', 'addbagalone'].includes(newoutfit)) return {}

    // 过滤主图没有
    if (productRecommendDetailLabel?.url) {
      const url = productRecommendDetailLabel?.url
      const beginIdx = url.lastIndexOf('/')
      const endIdx = url.lastIndexOf('.')
      const point = url.substring(beginIdx, endIdx)
      const condition = product_imgs.some(item=>item?.origin_image?.includes(point))
      if (!condition) {
        return {}
      }
    } 

    return newCompanionInfo
  },
  close_estimated_and_ab_price(state, getters, rootState, rootGetters) {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return rootState.productDetail.common.close_estimated_and_ab_price

    // todo: 旧数据流
    return rootGetters['newProductDetail/closeEstimatedAndAbPrice']
  },
  forbid_atmosphere_flow(state, getters, rootState, rootGetters){
    if(typeof window == 'undefined') return
    const { fs_abt } = getters
    const Displaytiming = fs_abt?.Displaytiming?.param?.Displaytiming || 0
    if(Displaytiming){
      let pageView = JSON.parse(localStorage?.getItem('goods_detail_page_view'))?.value || 0
      // 用户24小时内访问商详次数大于等于X次，返回false，展示人气氛围
      return Displaytiming > pageView
    }
    return false
  },
  is_store_business_brand(state, getters, rootState) {
    return rootState.productDetail.coldModules.storeInfo?.storeBusinessType == 2
  },
  store_brand_series_recommend_group(state, getters, rootState) { // TODO 旧数据流 后面需要替换
    return rootState.newProductDetail.storeBrandSeriesRecommendGroup
  },
  is_saved(state, getters, rootState) {
    return rootState.productDetail.hotModules.productInfo?.is_saved == '1'
  },
  /**
   * 属于要弹成人弹窗的商品
   */
  is_age_limit_goods(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo.noticeType == '1'
  },
  /**
   * 是否展示成人弹窗
   * 客户端需要单独请求接口获取过期状态
   */
  show_age_limit(state, getters, rootState) {
    return getters.is_age_limit_goods && rootState.productDetail.hybridModules.confirmedAgeLimitExpired
  },
  is_in_ssr() {
    return typeof window === 'undefined'
  },
  show_size_guide(state, getters, rootState, rootGetters) {
    const abtInfo = rootGetters['productDetail/product_detail_abt_info']
    const { content = [] } = rootState.productDetail.hotModules.productInfo?.cccDetailsTemplate || {}
    const sizeChart = content?.[0]?.sizeChart || 1
    const sizeguideshow = abtInfo?.sizeguideshow?.param?.sizeguideshow || ''
    const isCccTemplate = !['A', 'B'].includes(sizeguideshow)
    const size_guide_url = rootState.productDetail.coldModules?.saleAttr?.size_guide_url
    return size_guide_url && (sizeguideshow === 'B' || (isCccTemplate && sizeChart == 1))
  },
  un_listed(state, getters, rootState) {
    return +rootState.productDetail.coldModules.productInfo?.isNewProductUnSale === 1
  },
  is_local_Shipping(state, getters, rootState, rootGetters) {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    const ship_info = rootGetters['productDetail/ship_info'] || {}

    if (is_use_bff_fs) {
      return ship_info?.isLocalShipping === '1'
    }

    const siteMallInfo = rootState.newProductDetail.coldModules.productInfo?.siteMallInfo || []
    const mallCode = getters.mall_code 
    const mallTags = siteMallInfo.find(_ => _.mall_code == mallCode)?.mall_tags || ''
    return mallTags.indexOf('5') > -1 && mallTags.indexOf('4') == -1 && siteMallInfo?.length > 1
  },
  cate_infos_source(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo?.cateInfos
  },
  is_specified_hide_all_estimated(state, getters, rootState) {
    return +rootState.productDetail.hotModules?.configInfo?.isSpecifiedHideAllEstimated === 1
  },

  // 欧洲非到手价展示，新增的商卡配置
  hide_estimated_price_item_config(state, getters) {
    const isHideAllTypeEstimatedPrice = getters.is_specified_hide_all_estimated

    if (isHideAllTypeEstimatedPrice) {
      return {
        showEstimateTag: false, // 无到手价
        showEstimatedPriceReachThreshold: false, // 无到手价
        showEstimatedPriceOnSale: false, // 无到手价
        showEstimatedPrice: false, // 无到手价
        showABPriceOnSale: false, // 显示AB测试价格
        showABPriceTag: false, // 显示AB测试价格
        showNewStyleEstimated: true, // 无到手价新样式
      }
    }

    return {}
  },

  // 欧洲非到手价展示，新增的加车配置
  hide_estimated_price_quickadd_config(state, getters) {
    const isHideAllTypeEstimatedPrice = getters.is_specified_hide_all_estimated

    if (isHideAllTypeEstimatedPrice) {
      return {
        showEstimatedPrice: false,
        showAbPrice: true, // 展示ab价
      }
    }

    return {}
  },
  goods_url_name(state, getters) {
    const { main_sale_attribute_list } = getters
    return main_sale_attribute_list?.[0]?.goods_url_name || ''
  },
  show_placeholder_goods_info(state, getters, rootState) {
    return rootState.newProductDetail.common.topContentReady
  },
  related_local_goods(state, getters, rootState) {
    return rootState.productDetail?.coldModules?.productInfo?.related_local_goods || {}
  },
  show_cart_btn(state, getters, rootState, rootGetters) {
    return getters.fs_abt?.collectandaddcart?.p?.collectandaddcart === 'A'
  },
}
