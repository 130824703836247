import schttp from 'public/src/services/schttp'

// 推荐位
import recommend from './recommend'
import root from './user/root'
import userIndex from 'public/src/pages/user_index/store'
import wishlist from './user/wishlist'
import feedback_rec from './feedback_rec'
import config_index from './config_index'
import category from './category'
import getFooter from './footer'
import product_list_v2, { storeModuleName as productListStoreModuleName } from './product_list_v2'
import shein_picks, { storeModuleName as sheinPicksStoreModuleName } from './shein-picks'
import search_product_list, { storeModuleName as searchProductListStoreModuleName } from './search-product-list'
import productDetail from './product_detail'
import newProductDetail, { NEW_PRODUCT_DETAIL } from './product_detail_new' // 新版详情数据
import cart_v2 from './cart_v2'
import config_activity from './config_activity'
import dashboard from './user/dashboard'
import ccc_campaigns from './ccc-campaigns'
import pre_search from './pre_search'
import pay from './pay'
import checkout_v2 from '../../../../pages/checkout_new/store'
import ordersTrack from './ordersTrack'
import findOrder from './find_order'
import ocpLanding from './ocpLanding'
import discountChannel from './discount_channel_list'
import message from './user/message'
import user_store_follow from './user_store_follow'
import prime_credit_record from './prime_credit_record'
import pickedProducts from './picked_products'

// 红点标记
import redPoint from './utils/redPoint'
import { getInitCsChatMsgNum } from 'public/src/pages/common/CustomerService/util.js'

import { cccxEventBus } from 'public/src/pages/components/ccc/common/utils.js'
import { transformImg } from '@shein/common-function'
import { cartTagTip } from '@shein-aidc/bs-sdk-cart-tag-tip'
import { CartFloorEventBus } from 'public/src/pages/common/cartFloor/utils/event.js'
import { isSwitchBffSsr } from 'public/src/pages/common/bff-new/index.js'
import orderListStoreModule from './user/order_list.js'
import orderDetailStoreModule from './user/order_detail.js'
import cancelItemStoreModule from './user/order-cancel-item.js'
import orderVirtualDetailStoreModule from './user/order_virtual_detail'
import orderListBffStoreModule from './user/order_list_bff.js'
import { getBffVersionBySsr } from 'public/src/pages/user/child_pages/user_bff_version/index.js'
import { getCartBaseInfo } from 'public/src/pages/cart_v2/components/business/services.js'

// 浅拷贝, 用于初始化。如果扩展引用类型的属性数据需要调整复制方式
const commonHeaderBg =  {
  showBlock: false, // 是否显示背景
  topTextColor: '', // 顶部文本和icon颜色
  backgroundColor: '', // 背景色
  bgImgSrc: '', // 背景图
  immersiveFlag: false, // 是否沉浸式组件
  bannerBgColor: '', // 仅沉浸式组件透明蒙层加背景渐变色才有 banner区块渐变色
  homeTabStatus: false, // 控制首页频道tab
  stickyTop: false, // 头部是否吸顶状态
  searchInputBgColor: '' // 控制头部搜索框背景色
}
// 浅拷贝, 用于初始化。如果扩展引用类型的属性数据需要调整复制方式
const commonHeight =  {
  topHeaderHeight: 0, // 顶部header高度
  topTabHeight: 0, // 首页顶部 tab 高度
  topBgHeight: 0, // 头部+频道的高度
}

const state = function (context) {
  return {
    pageTriggerAppHeaderHydration: false,
    cccSkinHeaderLogo: typeof window !== 'undefined' ? gbCommonInfo.cccSkinHeaderLogo : context.locals?.cccSkinHeaderLogo,
    title: '',
    isClient: typeof window !== 'undefined',
    rootStatus: {
      loading: false,
      show_category: false,
      importSimilarVue: false,
      rankModal: false,
      exchangingSpaScene: false,
      exchangingSpaSceneInApp: false
    },
    rootSLoading: {
      type: 'curpage',
      show: false,
      containerCoverStyle: {},
      curPageMaskTopBoundSelector: '.j-common-logo-header',
      curPageScrollFix: true, // 当前加载 禁止其他滚动
      maskTransparent: false, // 蒙层透明 默认不透明
    },
    isNeedCommonHeader: true,
    cartInfo: {
      updateTime: 0,
      isFirstLoad: true,
      carts: [],
      sum: 0,
      info: {},
      language: {},
      isFreeShipping: typeof window !== 'undefined' ? (window.sessionStorage.getItem('isFreeShipping') === 'true' ? true : false) : false // 是否免邮(考虑以后所有涉及免邮相关的都可以用这个购物车状态信息)
    },
    footerCopyRigthText: {},
    commonHeaderInput: null,
    individuationData: {}, // 个性化信息

    redpoint: 0, // 红点状态，0=不显示，1=降价红点，2=低库存红点
    cacheRedpoint: 0, // 红点状态， 兼容老字段数据
    commonHeaderBg: { ...commonHeaderBg },
    searchInputBgColor: '', // 搜索框背景色
    showImmersiveBanner: false,
    hasNewTopBanner: false, // 新的公共 header 对应的新的 topBanner
    commonHeight: { ...commonHeight },
    hiddenBottomIcon: true, // 隐藏底部icon
    isAddCommonHeaderClassName: true, // 是否添加了通用头部类名
    goodsWishStatus: {}, // 商品收藏状态
    addBagGoods: {}, // 加车商品信息 目前只用goods_id 后续有需要可以扩展,
    indexUnReadInfo: {}, // 未读消息数据集合
    unReadInfo: {}, // 未读消息数据集合
    goodsCollected: [],  // 已经收藏的商品id集合
    currentDefaultKeyword: '',
    vuex_module_registing: false,
    searchSelectKeyWord: '',
    mixedKeyword: '', // 主搜词 +【分布式筛选词 (有就会拼上)】
    feedbackName: '', // 跨模块显示唯一负反馈弹窗
    searchFilterWords: [], // 搜索分布式筛选词
    // publicBannerPreviewType: '',
    // publicBannerPreviewTypeOld: '',
    // topBannerPublicBannerData: null,
    // policyBannerPublicBannerData: null,
    // topBannerLoading: false,
    // policyBannerLoading: false,
    isShowLoginModal: false,  //登录弹框是否打开
    unReadChatNum: 0, // 未读客服消息数量
    goodsDetailInfo: { // 商详提供 root store 信息
      goodsId: '',
      abtInfo: {},
      catId: '',
      productNavigationSearchInfo: {
        abtHit: false,
        currentTab: 'goods',
        tabList: []
      },
    },
    messageViewMode: 'Old', // 站内信页面展示Abt
    messageAbt: {},
    historyStoreSearchHotWord: {
      word: '',
      wordIdx: -1,
      type: '',
      position: 0,
      result_type: ''
    }, // 最近一次店铺进入预搜页搜索的热词
    inputDefaultWordsIndex: 0,  // 搜索框底纹词索引，目前只有预搜页用到
    storeConfigInfo: {},
  }
}

const getters = {

}

const mutations = {
  setPageTriggerAppHeaderHydration(state, payload) {
    state.pageTriggerAppHeaderHydration = !!payload
  },
  exchangingSpaScene(state, payload) {
    state.rootStatus.exchangingSpaScene = !!payload
  },
  exchangingSpaSceneInApp(state, payload) {
    state.rootStatus.exchangingSpaSceneInApp = !!payload
  },
  imgLazyload() {
    if (typeof GB_SHEIN_lazyLoadInstance !== 'undefined') {
      GB_SHEIN_lazyLoadInstance.update()
    }
  },
  changeRootStatus(state, payload) {
    state.rootStatus = { ...state.rootStatus, ...payload }
  },
  changeRootSLoadingStatus(state, payload) {
    // 避免未传 maskTransparent 的场景蒙层被隐藏
    state.rootSLoading = { ...state.rootSLoading, maskTransparent: false, ...payload }
  },
  assignRootState(state, payload) {
    Object.assign(state, payload)
  },
  changeNewTopBannerStatus(state, payload) {
    state.hasNewTopBanner = payload.hasNewTopBanner
  },
  changeHomeImmersiveInfo(state, payload) {
    if (payload?.commonHeaderBg) {
      // state.commonHeaderBg = { ...state.commonHeaderBg, ...commonHeaderBg, ...payload.commonHeaderBg }
      for (const key in commonHeaderBg) {
        state.commonHeaderBg[key] = payload.commonHeaderBg[key] || commonHeaderBg[key]
      }
    }
    if (payload?.commonHeight) {
      // state.commonHeight = { ...state.commonHeight, ...commonHeight, ...payload.commonHeight }
      for (const key in commonHeight) {
        state.commonHeight[key] = payload.commonHeight[key] || commonHeight[key]
      }
    }
    if (payload?.hasOwnProperty('showImmersiveBanner')) {
      state.showImmersiveBanner = payload.showImmersiveBanner
    }
    if (payload?.hasOwnProperty('searchInputBgColor')) {
      state.searchInputBgColor = payload.searchInputBgColor
    }
  },
  changeSeo(state, metaInfo = {}) {
    metaInfo = Object.assign({ meta_title: gbCommonInfo.IS_RW ? '' : gbCommonInfo.language.SHEIN_KEY_PWA_17609 }, metaInfo)
    const title = metaInfo.pageTitle || metaInfo.meta_title
    if (title) {
      try {
        $('title').text(decodeURIComponent(title))
      } catch (error) {
        $('title').text(title)
      }
    }
    if (metaInfo.pageDescription || metaInfo.meta_description || metaInfo.meta_keywords) {
      $('[name=description]').attr('content', metaInfo.pageDescription || metaInfo.meta_description || metaInfo.meta_keywords)
    }
    if (metaInfo.pageKeywords || metaInfo.meta_keywords) {
      $('[name=keywords]').attr('content', metaInfo.pageKeywords || metaInfo.meta_keywords)
    }
  },
  // 更改缓存的免邮状态单放这，不污染changeCartInfo
  changeFreeShippingState(state, { isFreeShipping = false }) {
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('isFreeShipping', isFreeShipping) // 解决当前页面刷新问题，把值缓存到sessionStorage中
    }
    state.cartInfo.isFreeShipping = isFreeShipping
  },
  changeCartNum(state, num) {
    cartTagTip.updateCartNum({ cartSumQuantity: num })
    state.cartInfo.sum = num
  },
  changeCartInfo(state, { carts = [], sum = 0, info = {}, language, isSSR, skipPrefetch } = {}) {
    if (!isSSR) {
      if (sum > 0) {
        $('.j-header-cart-num').removeClass('mshe-hide').html(sum > 99 ? '99+' : sum)
      } else {
        $('.j-header-cart-num').addClass('mshe-hide').html(0)
      }
    }
    if (typeof window !== 'undefined') {
      if (!state.cartInfo.isFirstLoad) {
        CartFloorEventBus?.emit?.('changeCartInfo',  {
          prevCart: state.cartInfo.carts,
          nowCart: carts
        })
      }
      state.cartInfo.isFirstLoad = false
    }
    cartTagTip.updateCartNum({ cartSumQuantity: sum })
    state.cartInfo.carts = carts
    state.cartInfo.sum = sum
    state.cartInfo.info = info
    state.cartInfo.updateTime = Date.now()
    if (language) {
      state.cartInfo.language = language
    }
    if (typeof window !== 'undefined') {
      cccxEventBus?.emit?.('free-shipping-update-cartinfo')
      if (!skipPrefetch && !location.href.includes('/cart')) {
        if (window.fetchCartContentInfo !== null) {
          window.fetchCartContentInfo = schttp({
            url: `/api/cart/get?prefetch=1`,
            headers: { 'x-sph': '1' },
          }).then((res) => {
            return new Promise(resolve => {
              if(res.ssrHTML && carts.length){
                requestIdleCallback(() => {
                  const fragment = document.createRange().createContextualFragment(res.ssrHTML)
                  Array.from(fragment.querySelectorAll('#cart-app .j-cart-main-img')).map(_ => _.getAttribute('src')).filter(_ => _).forEach(img => {
                    let  imgLoadHandle = new Image()
                    img && (imgLoadHandle.src = img)
                  })
                })
              }
              resolve(res)
            })
          })
        }
      }
    }
  },

  /**
     *
     * @param {商品基本信息} item
     */
  addProductDetailBfCache(state, item) {
    try {
      const data = {
        goods_img: item?.goods_img ? transformImg({ img: item.goods_img }) : item?.goods_lazy_img,
        goods_name: item.goods_name,
        salePrice: item.salePrice,
        retailPrice: item.retailPrice,
        unit_discount: item.unit_discount,
        relatedColor: item.relatedColor
      }
      if (item.commentInfo) {
        data.comment = {
          comment_rank: item.commentInfo.comment_rank_average,
          comment_num: item.commentInfo.comment_num
        }
      }

      sessionStorage.setItem('productDetailBfCache', JSON.stringify(data))
    } catch (error) {
      console.log(error)
    }
  },
  SET_COPYRIGHTDATA(state, payload) {
    state.footerCopyRigthText = payload
  },
  updateTitle(state, payload) {
    state.title = payload
  },
  updateUnReadInfo(state, payload) {
    state.unReadInfo = payload
  },
  updateIndexUnReadInfo(state, payload) {
    state.indexUnReadInfo = payload
  },
  setIndividuationData(state, payload) {
    state.individuationData = payload

    if (payload.newUrlCrowdInfo && payload.newUrlCrowdInfo.newUrlAbt && payload.newUrlCrowdInfo.newUrlAbt.p === 'type=A' && payload.newUrlCrowdInfo.jumpUrl) {
      $('.j-index-footer .j-index-tab-list-activity').attr('data-href', `${gbCommonInfo.langPath}/campaign/${payload.newUrlCrowdInfo.jumpUrl}?from_tabbar_new=1`)
    }
  },
  updateWishStatus(state, payload) {
    state.goodsWishStatus = payload
  },
  updateCollectedData(state, payload) {
    // payload { goodsId: 'string', isAdd: boolean }
    const { goodsId, isAdd } = payload
    if (goodsId) {
      if (isAdd) {
        state.goodsCollected.push(Number(goodsId))
      } else {
        const data = state.goodsCollected
        const index = data.findIndex(i => i == goodsId)
        data.splice(index, 1)
        state.goodsCollected = data
      }
    }
    window.localStorage.setItem('goodslist_like_goodsids', JSON.stringify(state.goodsCollected))
  },
  registeringVuexModuleManually(state, payload) {
    state.vuex_module_registing = payload
  },
  setGoodsDetailInfoForRoot(state, payload) {
    let { goodsId, catId } = payload
    state.goodsDetailInfo.goodsId = goodsId || state.goodsDetailInfo.goodsId
    state.goodsDetailInfo.catId = catId || state.goodsDetailInfo.catId
  },
  setGoodsDetailNavigationSearchInfoForRoot(state, payload) {
    let { currentTab, abtHit, tabList } = payload || {}
    if (currentTab) state.goodsDetailInfo.productNavigationSearchInfo.currentTab = currentTab
    if (Boolean(abtHit)) state.goodsDetailInfo.productNavigationSearchInfo.abtHit = abtHit
    if (tabList)state.goodsDetailInfo.productNavigationSearchInfo.tabList = tabList
  },
  setMessageViewMode(state, payload) {
    state.messageViewMode = payload
  },
}
const actions = {
  async getMessageAbt({ commit }) {
    const abtInfo = await window._abt_server_provider.getUserAbtResult({
      newPosKeys: 'Message2024'
    })
    commit('setMessageViewMode', abtInfo?.Message2024?.param?.MessageRevision2024)
    commit('message/setMessageAbt', abtInfo?.Message2024?.param)
  },

  async fetchCartInfo({ commit }) {
    const res = await getCartBaseInfo()
    commit('changeCartInfo', {
      sum: res.info?.cartSumQuantity || 0,
      carts: res.info?.carts || [],
      info: res.info || {},
    })
  },
  // 获取服务端时间
  fetchServerTime() {
    return schttp({ url: '/api/common/servicesTime/get' })
  },
  /**
   * 获取个性化信息
   */
  fetchIndividuation({ commit }) {
    const { lang, SiteUID, IS_RW } = gbCommonInfo

    if (IS_RW) return

    const individuationStorageData = window.sessionStorage.getItem(`individuationStorageData_${lang}_${SiteUID}`)
    const STORAGE_EXPIRE_TIME = 60 * 3 * 1000 // 缓存 3 分钟

    // 这两种情况走请求 1、Storage 的 Key 不存在  2、存在 Key 但已超过 3 分钟
    if (!individuationStorageData || (individuationStorageData && (Date.now() - Number(JSON.parse(individuationStorageData).expireTime) >= STORAGE_EXPIRE_TIME))) {
      schttp({ url: '/api/common/individuation/get' }).then(res => {
        window.sessionStorage.setItem(`individuationStorageData_${lang}_${SiteUID}`, JSON.stringify({
          data: res,
          expireTime: Date.now()
        }))

        commit('setIndividuationData', res)
      })
    } else {
      commit('setIndividuationData', JSON.parse(individuationStorageData).data)
    }
  },

  /**
   * 检查红点状态
   */
  async checkRedpoint({ state }, fn = () => { }) {
    // 是否优先展示InStock红点
    const WishlistInStock = gbCommonInfo.WISHLIST_ABT_COMPLETE === 'on' ? 'A' : (state.wishList_html?.Result?.abtData?.WishlistInStock?.p?.ShowWishlistInStock ?? 'A')

    const redpoint = await redPoint.changeRedpoint(fn, {
      WishlistInStock
    })

    // eslint-disable-next-line require-atomic-updates
    state.redpoint = redpoint
    // eslint-disable-next-line require-atomic-updates
    state.cacheRedpoint = redpoint
  },

  /**
   * 隐藏红点
   */
  hideRedpoint({ state }) {
    state.redpoint = redPoint.closeRedpoint()
    state.cacheRedpoint = state.redpoint
  },
  fetchDataFromBff({ }, data) {
    const { word_type, goods_id, cat_id, select_id, list_scene, page_title_name, scene, store_code } = data
    return schttp({
      url: '/product/search/v3/get_keywords',
      useBffApi: true,
      params: {
        goods_id,
        word_type,
        cat_id,
        select_id,
        list_scene,
        page_title_name,
        store_code: store_code || '',
        scene: scene ? scene : goods_id ? 'detail' : 'home',
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  // 获取是否展示红点 & 路由切换时使用
  async getCsChatMsgNum({ state }) {
    if (window.requestldleCallback) {
      window.requestldleCallback(async () => {
        state.unReadChatNum = await getInitCsChatMsgNum()
      })
    } else {
      state.unReadChatNum = await getInitCsChatMsgNum()
    }
  },
  // 获取是否展示红点 & 首次没请求则需要使用
  async getCsChatMsgNumFirst({ dispatch }) {
    dispatch('getCsChatMsgNum')
  },

}

function getOrderList (context) {
  const isBff = (typeof window === 'undefined' && typeof context?.useOrderListBff === 'boolean') ? context?.useOrderListBff : getBffVersionBySsr('order_list_v1')
  return isBff ? orderListBffStoreModule : orderListStoreModule
}

export default (context) => ({
  state: state(context),
  getters,
  mutations,
  actions,
  modules: {
    recommend,
    root,
    userIndex,
    wishlist,
    feedback_rec,
    config_index,
    category,
    [productListStoreModuleName]: product_list_v2,
    [sheinPicksStoreModuleName]: shein_picks,
    [searchProductListStoreModuleName]: search_product_list,
    productDetail,
    [NEW_PRODUCT_DETAIL]: newProductDetail,
    config_activity,
    dashboard,
    'ccc-campaigns': ccc_campaigns,
    pre_search,
    checkout: checkout_v2,
    pay,
    footer: getFooter(context),
    ordersTrack,
    findOrder,
    ocpLanding,
    discountChannel,
    pickedProducts,
    message,
    user_store_follow,
    prime_credit_record,
    cart_v2,
    orderList: getOrderList(context),
    orderDetail: orderDetailStoreModule,
    orderVirtualDetail: orderVirtualDetailStoreModule,
    cancelItemModule: cancelItemStoreModule,
  }
})
