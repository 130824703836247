import schttp from 'public/src/services/schttp'
import abtInfo from './abtInfo'
import baseRecommend from './base-recommend'
import DETAIL_API_LOCATION from '../../../api'
import { useItemConfig, EItemConfigType } from 'public/src/pages/goods_detail_v2/utils/ProductConfigManager/index'
import recommendExposureListInstance from 'public/src/pages/goods_detail_v2/utils/recommendExposureList.js'
import { useProductCardUtils } from 'public/src/pages/goods_detail_v2/utils/recommend.js'
import { EWishsimilarType, RecQueryType } from 'public/src/pages/goods_detail_v2/innerComponents/drawer/RecommendDrawer/constants.js'
import { getPopupInstance } from 'public/src/pages/goods_detail_v2/innerComponents/recommend/GetTheLookPopup/main-load.js'
import { getCalcBundlePrice } from 'public/src/pages/goods_detail_v2/innerComponents/recommend/CommonCart/utils.js'
import { goodsDetailScroll } from 'public/src/pages/product_app/store/modules/product_detail/utils/recommendUtils.js'
import { getQueryString, template } from '@shein/common-function'
import { openAddonItemDrawer } from 'public/src/pages/goods_detail_v2/innerComponents/recommend/AddBagRec/utils.js'
import recommendFilterInstance from 'public/src/pages/goods_detail_v2/utils/recommendFilter.js'
import { deduplicationGoodsId } from 'public/src/pages/product_app/store/modules/product_detail/utils/common.js'

export default {
  namespaced: true,
  state: {
    ccc_feed_back: null,
    show_outfit_alone: false,
    combo_buy_products: [],
    combo_buy_price: {},


    find_similar_float_info: {
      show: false,
      products: [],
      from: '',
      filterSize: '',
      hasMoreProducts: false,
    },
    find_similar_api_time_stamp: '',
    add_bag_rec_data: [], // 加车推荐弹窗数据
    bff_coupon_info: null, // 交易中间层优惠券信息
    ofbw_cat_data: [], // ofbw分类数据
    ofbw_list_data: [], // ofbw列表数据
    add_bag_rec_coupon_list_data: [], // 加车推荐优惠券列表数据
    fetch_rec_data_promise_status: Promise.resolve('failure'), // 加车推荐接口请求状态 
    add_on_item_goods_id: '', // 缓存凑单弹窗里的凑单推荐商品id
    activity_combobuy_data: { // 活动组合购数据
      products: [],
      unitDiscount: '' // 活动组合购折扣
    },
    is_ymal_loaded: false, // ymal 已加载
    is_ymal_exposed: false, // ymal 是否已曝光
  },
  getters: {
    ...baseRecommend,
    ...abtInfo,
    get_the_lookA_info(state, getters, rootState, rootGetters) {
      const outfits_info = rootGetters['productDetail/MainPicture/outfits_info']
      return outfits_info.gtlInfo || {}
    },
    /**
   * 店铺推荐商品id集合, 用,分隔
   */
    shop_rec_product_ids(state, getters, rootState, rootGetters) {
      const shop_rec_products = rootGetters['productDetail/DetailSignBoard/shop_rec_products']
      if (!shop_rec_products?.length) return ''
      const ids = shop_rec_products.map(item => item.goods_id).filter(d => !!d)

      return ids?.length ? ids.join(',') : ''
    },
    // 多属性关联模块
    under_photo_products(state, getters, rootState, rootGetters) {
      let multiPropertyProducts = rootState.productDetail.hotModules.multiPropertyProducts || []
      const relatedSize = rootGetters['productDetail/common/related_size'] || []
      let result = deduplicationGoodsId(multiPropertyProducts?.[0]?.relateSkcAttrList || [], relatedSize.map(item => item.goods_id))
      result = result?.slice(0, 30) || []
      return result
    },
    // 多属性关联模块
    under_size_products(state, getters, rootState, rootGetters) {
      let multiPropertyProducts = rootState.productDetail.asyncModules.multiPropertyProducts || []
      const relatedSize = rootGetters['productDetail/common/related_size'] || []
      let result = deduplicationGoodsId(multiPropertyProducts?.[0]?.multiPropertyProductsList || [], relatedSize.map(item => item.goods_id))
      result = result?.slice(0, 30) || []
      return result
    },
    has_multi_property_products(state, getters){
      return Boolean(getters.under_photo_products?.length > 1 || getters.under_size_products?.length) 
    },
    under_photo_multi_property_products(state, getters, rootState, rootGetters){
      // const fsAbt = rootGetters['productDetail/common/fs_abt']
      const product_detail_abt_info = rootGetters['productDetail/product_detail_abt_info']
      const abtText = product_detail_abt_info?.similarrelate?.param?.similarrelate || 'notshow' 
      // const MAIN_BFF_APOLLO = rootState.productDetail.MAIN_BFF_APOLLO || {}
      // return true
      return Boolean(getters.under_photo_products?.length > 1 && abtText === 'underphoto')
    },
    under_size_multi_property_products(state, getters, rootState, rootGetters){
      // const fsAbt = rootGetters['productDetail/common/fs_abt']
      const product_detail_abt_info = rootGetters['productDetail/product_detail_abt_info']
      const abtText = product_detail_abt_info?.similarrelate?.param?.similarrelate || 'notshow' 
      // const MAIN_BFF_APOLLO = rootState.productDetail.MAIN_BFF_APOLLO || {}
      // return true 
      return Boolean(getters.under_size_products?.length) && abtText === 'undersize'
    },

    attributes_style_multi_products(state, getters, rootState, rootGetters) {
      const hide_one_size_module = rootGetters['productDetail/SizeBox/hide_one_size_module']
      const size_attr_info = rootGetters['productDetail/common/size_attr_info']
      // 是否有 size 模块
      const hasSizeModule = !hide_one_size_module && size_attr_info?.attr_id

      const fs_abt = rootGetters['productDetail/product_detail_abt_info']
      const groupInSizePosition = fs_abt?.goodsSizeGroupRelated?.p?.goodsSizeGroupRelated === 'size'
      const goodsId = rootGetters['productDetail/common/goods_id']
      
      const { under_photo_products = [] } = getters
      const multiProducts = under_photo_products.filter(item => +item.goodsId !== +goodsId)
      if (!multiProducts.length) {
        return multiProducts
      }

      // 如果不展示 size 模块且命中 size abt，大小码放在多属性前展示
      if (!hasSizeModule && groupInSizePosition) {
        const sale_attr_groups = rootGetters['productDetail/common/sale_attr_groups']

        const groups = (sale_attr_groups?.groups || []).reduce((memo, cur) => {
          if (cur.isCurrentGroup !== '1') {
            memo.push({
              ...cur,
              isGroup: true
            })
          }
          return memo
        }, [])
        return [...groups, ...multiProducts]
      }

      return multiProducts
    },

    show_attributes_style_multi_products(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/product_detail_abt_info']
      const isAttributeStyle = fs_abt?.similarrelate?.param?.similarrelate === 'attributes'

      return isAttributeStyle && !!getters.attributes_style_multi_products.length
    },
    multi_property_products_goodsIds(state, getters){
      if(getters.under_photo_multi_property_products){
        return getters.under_photo_products.map(item => item.goodsId)
      }else if(getters.under_size_multi_property_products){
        return getters.under_size_products.map(item => item.goods_id)
      } else if (getters.show_attributes_style_multi_products) {
        return getters.attributes_style_multi_products.filter(item => !item?.isGroup).map(item => item.goodsId)
      }
      return []
    },
    // 售罄弹窗用到的数据
    find_similar_float_box(state, getters, rootState, rootGetters) {
      const is_skc_sold_out = rootGetters['productDetail/common/is_skc_sold_out']
      const main_sale_text = rootGetters['productDetail/common/cur_main_sale_attribute']?.attr_value || ''
      const sku_info = rootGetters['productDetail/common/sku_info']
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const base_info = rootGetters['productDetail/common/base_info']
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const { popupcolor, popupsize } = fs_abt?.soldoutsimilarstyle?.p || {}
      const style_config = {
        popupcolor,
        popupsize
      }

      const texts = {
        viewAll: language_v2.SHEIN_KEY_PWA_25025 || '',
        viewMore: language_v2.SHEIN_KEY_PWA_18778 || '',
        findSimilar: language_v2.SHEIN_KEY_PWA_25001 || '',
        showSimilarSize: language_v2.SHEIN_KEY_PWA_25000 || '',
        showSimilarItems: language_v2.SHEIN_KEY_PWA_24999 || '',
      }

      return {
        is_skc_sold_out,
        main_sale_text,
        sku_info,
        style_config,
        base_info,
        texts
      }
    },
    // 活动组合购用到的数据
    activity_combobuy_info(state, getters, rootState, rootGetters) {
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const texts = {
        off: language_v2.SHEIN_KEY_PWA_35905 || '{0} OFF',
        buyOneMore: language_v2.SHEIN_KEY_PWA_35907 || 'Buy One More, Save on Both',
        thisItem: language_v2.SHEIN_KEY_PWA_24207 || 'This Item',
        justAddOneMore: language_v2.SHEIN_KEY_PWA_35908 || 'Just Add One More',
        added: language_v2.SHEIN_KEY_PWA_34540 || 'Added',
        offNow: language_v2.SHEIN_KEY_PWA_35909 || '{0} OFF Now, Checkout!',
        hasBeenAdded: language_v2.SHEIN_KEY_PWA_36096 || 'This item has been added. Please select another item',
      }
      const unitDiscount = state.activity_combobuy_data.unitDiscount || ''
      const isPaidUser = !!rootGetters['productDetail/common/is_paid_user']
      const isHitComplianceMode = rootGetters['productDetail/common/is_hit_compliance_mode']
      const mainGoodsId = rootGetters['productDetail/common/goods_id'] || ''
      // const shop_rec_products = rootGetters['productDetail/DetailSignBoard/shop_rec_products']
      // shop_rec_products.forEach((item, index) => {
      //   item._cartInfo = {
      //     isMainGoods: index === 0,
      //     hasAddCart: false
      //   }
      // })
      return {
        texts,
        isPaidUser,
        isHitComplianceMode,
        mainGoodsId,
        unitDiscount: `${unitDiscount}%`,
        unitDiscountNum: unitDiscount,
        products: state.activity_combobuy_data.products
        // products: shop_rec_products
      }
    },
    show_activity_combobuy_module(state) {
      return state.activity_combobuy_data.products.length > 0
    }
  },
  actions: {
    get_ccc_feed_back: async ({ state, commit, rootGetters }) => {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      // 当实验命中pop时，根据拓展弹窗在CCC配置的内容展示（含仅有找相似、仅有负反馈、同时有找相似和负反馈），如果CCC未配置拓展弹窗，则不展示
      if (fs_abt?.ProductDetailYouMayAlsoLikeExpandPop?.p !== 'pop') return
      // 加载成功不重复请求
      if (state.ccc_feed_back) return state.ccc_feed_back
      try {
        const res = await schttp({
          url: DETAIL_API_LOCATION.CCC_FEED_BACK,
          method: 'GET',
          useBffApi: true,
        })

        const info = res?.info?.extendConfigDetail?.find(item => {
          return item.pageStyleKey === 'page_detail_you_may_also_like' && item.sceneKey === 'TWO_IN_A_ROW' // 详情页推荐 一行二图
        })

        if (info && info.effectTime && info.endTime) {
          const nowTime = Date.parse(new Date()) / 1000
          if (nowTime > info.effectTime && nowTime < info.endTime && ((info.negFeedbackShow == 1 && info.negFeedbackInfo.length > 0) || info.similarShow == 1)) {
            commit('update_ccc_feed_back', info)
            return info
          }
        }

        commit('update_ccc_feed_back', null)
        return null
      } catch(err) {
        console.error('get_ccc_feed_back', err)
        return null
      }
    },
    /**
   * 打开推荐弹窗 
   * @param {*} name 弹窗场景名 (wish-收藏成功弹窗)
   * @param {*} extendConfig
   */
    async open_detail_recommend_drawer({ dispatch, commit, getters, rootGetters }, payload) {
      const [RecommendDrawerInstance, config] = await Promise.all([
        import('public/src/pages/goods_detail_v2/innerComponents/drawer/RecommendDrawer/index.js').then(res => res.default),
        new Promise((resolve) => {
          const { name, extendConfig } = payload || {}
          const _language = rootGetters['productDetail/common/language_v2']
          const product_items_language = rootGetters['productDetail/common/product_items_language']
          const language = {
            ...(_language || {}),
            ...(product_items_language || {})
          }

          const isHitComplianceMode = rootGetters['productDetail/common/is_hit_compliance_mode']
          const goodsId = rootGetters['productDetail/common/goods_id']
          const fsAbt = rootGetters['productDetail/common/fs_abt']
          const productBffDependData = getters.product_bff_depend_data
  
          const wishsimilartype = EWishsimilarType[fsAbt?.wishsimilar?.param?.wishsimilartype] || EWishsimilarType.big
          const _isWishTypeMedium = wishsimilartype === EWishsimilarType.medium
          const _isWishTypeSmall = wishsimilartype === EWishsimilarType.small
  
          const { itemConfig: itemConfigPic2 } = useItemConfig({
            name: 'DetailRecommendDrawerPic2',
            type: EItemConfigType.Pic2,
            mergeConfig: {
              useOwnClickAddBagAnalysis: true,
            }
          })
  
          const getProductItemsConfig = () => {
            if (_isWishTypeMedium) {
              const { itemConfig } = useItemConfig({
                name: 'DetailRecommendDrawerPic3',
                type: EItemConfigType.Pic3,
              })
              return itemConfig.value
            }
            if (_isWishTypeSmall) {
  
              const { itemConfig } = useItemConfig({
                name: 'DetailRecommendDrawerSPic3OnlyPrice',
                type: EItemConfigType.sPic3OnlyPrice,
              })
              return itemConfig.value
            }
            // 一行二图推荐场景
            return itemConfigPic2.value
          }
  
          const productItemsConfig = { ...getProductItemsConfig() }
          const MAX_COUNT = 3
  
          const config = {
            styleType: wishsimilartype,
            goodsId,
            language,
            showQuickAddEstimatedPrice: !isHitComplianceMode,
            clearRepeatArr: recommendExposureListInstance.getList(),
            productItemsConfig,
            productItemsListConfig: { ...itemConfigPic2.value },
            addCartCallback: {
              handleChangeLocalCountry: (value) => {
                // 数据联动控制
                commit('newProductDetail/common/updateCurrentLocalCountry', value)
              }
            },
            ...(extendConfig || {}),
          }

          if (name === 'wish') {
            config.listAnalysisData = {
              'active-from': 'collection_success',
              poskey: 'SimilarItem',
            }
  
            config.productItemsConfig.sa = config.productItemsListConfig.sa = {
              activity_from: 'collection_success'
            }
  
            if (typeof sessionStorage !== 'undefined') {
              const _KEY = 'productDetail_addWishRecommendCount'
              const resetCountFn = () => {
                sessionStorage.setItem(_KEY, 0)
              }
              const count = Number(sessionStorage.getItem(_KEY))
              if (count >= MAX_COUNT) {
                resolve(null)
              }
              sessionStorage.setItem(_KEY, count + 1)
  
              config.openQuickAfter = resetCountFn
              config.clickItemAfter = resetCountFn
              config.clickViewMoreAfter = resetCountFn
              config.clickViewAllAfter = resetCountFn
            }

            // 数据处理
            config.integrateFetchRecommend = async (params) => {
              const { similarItemRecProducts = [], hasNextPage = '0' } = await dispatch('get_recommend_collect_similar', {
                queryType: RecQueryType.similarItem,
                ...params
              })
              
              // BFF 商卡数据转换
              const { goodsTransformationProductsWithSetup } = useProductCardUtils(productBffDependData)
              const products = goodsTransformationProductsWithSetup(similarItemRecProducts) || []

              return {
                products,
                hasNextPage
              }
            }
          }
          resolve(config)
        })
      ])
      const { name } = payload || {}
      if (!config) return false
  
      return RecommendDrawerInstance.open(name, config)
    },

    /**
     * 售罄找相似相关
     * @param {*} params: from: { skuChange: sku切换, skcChange: skc切换, pageInit: 页面初始化}
     * @param {*} config 
     * @returns 
     */
    async update_find_similar_float({ state, commit, getters, rootGetters, dispatch }, config = {}) {
      const { from } = config
      const findSimilarFloatInfo = state.find_similar_float_info || {}
      const skuInfo = rootGetters['productDetail/common/sku_info']
      const mallStock = rootGetters['productDetail/common/mall_stock']
      const isSoldOut = rootGetters['productDetail/common/is_sold_out']
      const unListed = rootGetters['productDetail/common/un_listed']
      const baseInfo = rootGetters['productDetail/common/base_info']
      const fsAbt = rootGetters['productDetail/common/fs_abt']

      // 重置状态
      const initFindSimilarInfo = () => {
        commit(
          'update_find_similar_float_info',
          {
            loading: false,
            show: 0,
            products: [],
            from,
            filterSize: '',
            hasMoreProducts: false,
          })
      }
    
      const productBffDependData = getters.product_bff_depend_data
    
      const { cat_id, goods_id } = baseInfo
      const timeStamp = new Date().getTime()
      commit('update_find_similar_api_time_stamp', timeStamp)

      // 跟售罄tip判断条件一致
      if (!((isSoldOut || mallStock === 0) && !unListed)) {
        initFindSimilarInfo()
        return
      }
      // 售罄sku切换时展示loading
      commit(
        'update_find_similar_float_info',
        {
          ...findSimilarFloatInfo,
          loading: true
        })
          
      const { soldoutsimilar, similaritemsnew } = fsAbt || {}
      const soldoutsimilarParam = soldoutsimilar?.param?.soldoutsimilar
    
      if (!soldoutsimilarParam || soldoutsimilarParam === 'none') {
        initFindSimilarInfo()
        return
      }
    
      // 当前sku尺码
      const currentSizeInfo = skuInfo?.sku_sale_attr?.find(item => item?.attr_id == 87) || {}
      const filterSize = (soldoutsimilarParam === 'show_limit' && currentSizeInfo?.attr_value_name) || ''
    
      const { products: similarItemRecProducts
      } = await dispatch('get_recommend_similar_list', { goods_id, cat_id, rule_id: similaritemsnew?.p || '', size: filterSize })
    
      const { goodsTransformationProductsWithSetup } = useProductCardUtils(productBffDependData)
      const products = goodsTransformationProductsWithSetup(similarItemRecProducts) || []
      
      if (state.find_similar_api_time_stamp != timeStamp) return
      // 时间戳限制接口更新
      if (!products || products?.length < 10) {
        initFindSimilarInfo?.()
        return
      }
      commit(
        'update_find_similar_float_info',
        {
          loading: false,
          show: (findSimilarFloatInfo?.show || 0) + 1,
          products,
          from,
          filterSize: filterSize,
          hasMoreProducts: products.length === 20,
        })
    
    },
    /**
     * ================================ 接口请求相关 ====================================
     */

    /**
     * 商详收藏后弹出similar推荐
     * Doc: post_product_detail_recommend_collect_similar/doc
     */
    get_recommend_collect_similar: ({ rootGetters }, { queryType, pageNum = 1, limit = 20 }) => {
      return new Promise(resolve => {
  
        const goodsId = rootGetters['productDetail/common/goods_id'] || ''
        const cateId = rootGetters['productDetail/common/cat_id'] || ''
        const fs_abt = rootGetters['productDetail/common/fs_abt'] || ''
  
        const { rec_ver_s143_match = '', rec_ver_s143_rank = '', rec_ver_s143_rerank = '' } = fs_abt?.SimilarItem?.p || {}
  
        const params = {
          abtParamsSimilarItem: {
            rec_ver_s143_match,
            rec_ver_s143_rank,
            rec_ver_s143_rerank,
          },
          pageQueryParam: {
            page: pageNum,
            limit,
            queryType,                          // 查询类型 1-similarItem 2-组合购 3-oftenBoughtWith 4-quickShip推荐
          },
          attribute: '',
          filterCateId: '',
          cateId,
          goodsId,
          isAddCart: '0',                       // 是否加车 
          isOnlyNeedProductCardPrice: '0',      // 是否只需要商卡价格 1：是。其他否
        }
  
        schttp({
          url: DETAIL_API_LOCATION.RECOMMEND_COLLECT_SIMILAR,
          method: 'POST',
          data: params,
          useBffApi: true
        }).then(({ info }) => {
          return resolve(info || {})
        })
      })
    },
    fetch_activity_combobuy_data: async ({ dispatch, commit, state, getters }) => {
      const activity_products = state.activity_combobuy_data.products || [] // 再次加车，活动组合购数据如果有不请求了
      if(activity_products.length > 0) return 
      const data = await dispatch('get_activity_combobuy_data', { isAddMainGoodsCart: true })
      const { bundledPurchaseProducts = [], unitDiscount = '' } = data?.bundledPurchaseInfo || {}
      if(!bundledPurchaseProducts.length)  return
      const [first, ...rest] = bundledPurchaseProducts
      const filterRestArr = recommendFilterInstance.filterRecommendData(rest)
      if(filterRestArr.length < 20) return
      recommendFilterInstance.pushGoodsIds(filterRestArr, 20)
      const endProducts = [first, ...filterRestArr]
      const productBffDependData = getters.product_bff_depend_data
      const { goodsTransformationProductsWithSetup } = useProductCardUtils(productBffDependData)
      const recProducts = goodsTransformationProductsWithSetup(endProducts.slice(0, 21)) || []
      recProducts.forEach((item, index) => {
        item._cartInfo = {
          isMainGoods: index === 0,
          hasAddCart: false
        }
      })
      commit('update_activity_combobuy_data', { products: recProducts, unitDiscount })
    },
    get_activity_combobuy_data: ({ rootGetters }, { isAddMainGoodsCart = false, goodsIdSkuMallCode = '' }) => {
      return new Promise(resolve => {
        const goodsId = rootGetters['productDetail/common/goods_id'] || ''
        const cateId = rootGetters['productDetail/common/cat_id'] || ''
        const sku_code = rootGetters['productDetail/common/sku_code'] || ''
        const mall_code = rootGetters['productDetail/common/mall_code'] || ''
        const params = {
          goodsId,
          cateId,
          pageQueryParam: {
            queryType: '6', // 活动组合购推荐
          },
          bundlePurchaseReqParam: {
            goodsIdSkuMallCode: goodsIdSkuMallCode || `${goodsId}-${sku_code}-${mall_code}`, // goodsId-skuCode-mallCode
            isAddMainGoodsCart: isAddMainGoodsCart ? '1' : '0',
          }
        }
        schttp({
          url: DETAIL_API_LOCATION.RECOMMEND_COLLECT_SIMILAR,
          method: 'POST',
          data: params,
          useBffApi: true
        }).then(({ info }) => {
          return resolve(info || {})
        })
      })
    },
    /** 初始化组合购数据 */
    async init_combo_buy_data ({ dispatch, commit }) {
      const result = await dispatch('get_together_better_deals_price', { isEnterDetailCall: '1' })
      let { togetherBetterDealsProdList } = result || {}
      // 推荐模块去重
      togetherBetterDealsProdList = recommendFilterInstance.filterRecommendData(togetherBetterDealsProdList)
      recommendFilterInstance.pushGoodsIds(togetherBetterDealsProdList, 20)

      commit('update_combo_buy_products', togetherBetterDealsProdList)
      commit('update_combo_buy_price', result)
    },
    /** 更新组合购价格 */
    async get_combo_buy_price ({ dispatch, commit }, args) {
      const result = await dispatch('get_together_better_deals_price', args)
      commit('update_combo_buy_price', result)
      return !!result?.bundlePrice?.amountWithSymbol
    },
    /** 获取组合购数据 */
    async get_together_better_deals_price ({ rootGetters }, args) {
      const goods_id = rootGetters['productDetail/common/goods_id'] || ''
      const goods_sn = rootGetters['productDetail/common/goods_sn'] || ''
      const is_paid_user = rootGetters['productDetail/common/is_paid_user'] || ''
      const mall_code = rootGetters['productDetail/common/mall_code'] || ''
      const is_sold_out = rootGetters['productDetail/common/is_sold_out']
      const cat_id = rootGetters['productDetail/common/cat_id']

      const { selectedGoodsIdSkus = '', updateGoodsId = '', isEnterDetailCall = '0' } = args || {}

      const params = {
        catId: cat_id,
        goodsId: goods_id,
        goodsSn: goods_sn,
        mallCode: mall_code,
        isAddCart: '1',
        isEnterDetailCall,
        isMainGoodsSoldOut: is_sold_out ? '1' : '0',
        isPaidMember: is_paid_user ? '1' : '0',
        selectedGoodsIdSkus,
        updateGoodsId,
      }

      const result = await schttp({
        method: 'GET',
        url: DETAIL_API_LOCATION.RECOMMEND_GET_TOGETHER_BETTER,
        params: params,
        useBffApi: true
      })

      return result?.info || {}
    },
    async open_get_the_look_new_popup({ rootState, getters, dispatch, rootGetters, commit }, payload) {
      const { initCateTab = '', activeFrom = '', swiperIndex = 0 } = payload || {}

      const GetTheLookPopupInstance = await getPopupInstance()

      const data = rootState.productDetail.asyncModules.newCompanionInfo
      if (!data?.productNewCompanionList?.length) return

      const _language = rootGetters['productDetail/common/language_v2']
      const product_items_language = rootGetters['productDetail/common/product_items_language'] // todo_del
      const language = {
        ...(_language || {}),
        ...(product_items_language || {})
      }

      const get_the_look_abt_info = getters.get_the_look_abt_info

      const isPaidUser = !!rootGetters['productDetail/common/is_paid_user']
      const isHitComplianceMode = rootGetters['productDetail/common/is_hit_compliance_mode']
      const complianceMode = rootGetters['newProductDetail/common/complianceMode']

      const sku_code = rootGetters['productDetail/common/sku_code']
      const is_sold_out = rootGetters['productDetail/common/is_sold_out']

      const _final_sku_code = !is_sold_out && sku_code || ''

      const goodsId = rootGetters['productDetail/common/goods_id']
      const goods_sn = rootGetters['productDetail/common/goods_sn']
      const catId = rootGetters['productDetail/common/cat_id']

      const product_bff_depend_data = getters.product_bff_depend_data

      const bff_gtl_main_api = async (args) => {
        return dispatch('productDetail/get_new_companion_module_api', args, { root: true })
      }

      const bottomRecParams = get_the_look_abt_info.isOutfitShowYmalSource ? {
        attribute: rootState.newProductDetail.common.mainAttribute || rootState.newProductDetail.common.attribute,
        isAddCart: rootState.newProductDetail.common.addSkcCompleteStatus ? '1' : '0',
        goods_id: goodsId,
        goods_sn,
        cat_id: catId,
      } : {}

      const showAddbagTips = activeFrom === 'addbagoutfit'
      const addToBagMainGoods = rootState.newProductDetail.common.addToBagMainGoods
      const mainGoodsAlone = ['addbagoutfit', 'outfitalone', 'outfitymal'].includes(activeFrom) && !!addToBagMainGoods?.skuCode
      const mainSkuCode = mainGoodsAlone ? addToBagMainGoods?.skuCode : _final_sku_code


      const config = {
        goodsId,
        catId,
        mainSkuCode,
        data,
        language,
        initCateTab,
        // ymalsCateInfo,
        isPaidUser,
        config: { // 配置项
          ...get_the_look_abt_info,
          product_bff_depend_data,
          bff_gtl_main_api,
          // 底部推荐位请求参数
          bottomRecParams,
          complianceMode,
          isHitComplianceMode,
          showAddbagTips,
          mainGoodsAlone,
          addToBagMainGoods,
          swiperIndex,
          activeFrom,
        },
        callback: {
          onAddCartSuccess: async () => {
            // 更新购物车数量
            await dispatch('fetchCartInfo', null, { root: true })
            window.vBus && window.vBus.emit('triggerAddCompletedFloatCart', { animation: false })
          },
          onUpdateCoupon: () => {
            dispatch('newProductDetail/updateEstimatedCouponList', { noUpdateRecommend: true }, { root: true })
          },
          onGoodsDetailScroll: () => {
            commit('productDetail/CommentPopup/update_need_scroll_review_ymal', true, { root: true })
            dispatch('productDetail/Recommend/set_goods_detail_scroll', {}, { root: true })
          }
        }
      }

      return GetTheLookPopupInstance.open(config)
    },

    /**
     * 获取找相似列表
     * @param {*} goods_id 
     * @param {*} cat_id 
     * @param {*} rule_id 
     * @returns 
     */
    get_recommend_similar_list: async ({}, { goods_id, cat_id, rule_id, page = 1, limit = 20, size = '' }) => {
      const params = {
        goods_list: `${goods_id}_${cat_id}`,
        scene: 'detail',
        cate_id: cat_id,
        page,
        limit,
        rule_id: rule_id,
        size
      }

      return new Promise((resolve) => {
        schttp({
          url: DETAIL_API_LOCATION.RECOMMEND_SIMILAR_LIST,
          method: 'POST',
          data: params,
          useBffApi: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(({ info }) => {
          resolve(info || {})
        })
      })
    },
    // 加车成功跳转到推荐
    set_goods_detail_scroll({}, { isReview, ignoreSelectors } = {}) {
      setTimeout(() => goodsDetailScroll({ isReview, ignoreSelectors }), 1000)
    },

    //加车成功推荐弹窗逻辑start-----
    // bff融合打开加车推荐弹窗
    async open_add_bag_recommend_drawer({ getters, dispatch, rootGetters, state, commit }, payload) {
      const { isDirect, isReview } = payload || {}
      const addBagRecommendConfig = getters.add_bag_recommend_config
      const carouse_data = rootGetters['productDetail/MainPicture/carouse_data']  
      const product_imgs = rootGetters['productDetail/common/product_imgs']

      const { tip: soldOutTips } = rootGetters['productDetail/common/will_sold_out_tips_info'] || {}
      const product_detail_abt_info = rootGetters['productDetail/product_detail_abt_info'] || {}
      const addbagsuccess = product_detail_abt_info?.addbagsuccess || {}
      const { addbagRecQuickShow, addbagRecAllShow, addbagRecStyleOne, addbagRecStyleFour } = addBagRecommendConfig
      // 不展示加车后弹窗推荐 时机不满足
      if(!((addbagRecQuickShow && !isDirect) || addbagRecAllShow)) return false
      const fetchStatus = await state.fetch_rec_data_promise_status
      if(fetchStatus === 'failure') {
        return false
      }
      const goods_id = rootGetters['productDetail/common/goods_id'] || ''
      const cat_id = rootGetters['productDetail/common/cat_id'] || ''

      const couponInfos = state.bff_coupon_info || []
      const noMeetCouponItems =  couponInfos.filter(item=>item.meetHigh == 0)
      const mainCouponItem = noMeetCouponItems?.[0]
      // 下滑ymal场景
      const cbSetGoodsDetailScrollFn = () => { dispatch('productDetail/Recommend/set_goods_detail_scroll', {}, { root: true }) }
      const update_need_scroll_review_ymal = () => { commit('productDetail/CommentPopup/update_need_scroll_review_ymal', true, { root: true }) }

      let resetCountFn = () => {}
      if (typeof sessionStorage !== 'undefined' && !addbagRecStyleFour) {
        const _KEY = 'productDetail_addBagRecCount'
        resetCountFn = () => {
          sessionStorage.setItem(_KEY, 0)
        }
        const count = Number(sessionStorage.getItem(_KEY))
        if (count >= 3) {
          return false
        }
        sessionStorage.setItem(_KEY, count + 1)
      }

      const inventoryInfo = {
        soldOutTips,
        fsAbt: {
          addbagsuccess
        },
        carouseData: carouse_data,
        mainImg: product_imgs?.[0]?.origin_image || '',
        addbagRecStyleOne
      }
      // 样式一 打开凑单弹窗 
      if(addbagRecStyleOne) {
        if(!mainCouponItem) return false
        const couponCodes = noMeetCouponItems.map(item=>item.couponCode)
        const queryInfo = {
          addOnType: 2,
          sceneId: 152, // 推荐场景id
          goodsIds: [goods_id],
          cateIds: [cat_id],
        }
        openAddonItemDrawer({
          mainCouponCode: couponCodes[0] || '',
          queryInfo,
          isReview,
          resetCountFn,
          cbSetGoodsDetailScrollFn,
          update_need_scroll_review_ymal,
          inventoryInfo,
          fsAbt: {
            addbagsuccess
          },
          secondaryCoupon: couponCodes.slice(1)
        })
        return true
      } else if(addbagRecStyleFour){
        // 样式四（打开getTheLook）
        setTimeout(() => {
          dispatch('newProductDetail/openGetTheLookNewPopup', { initCateTab: '', activeFrom: 'addbagoutfit' }, { root: true })
        }, 1000)
        return true
      } else {  // 样式二 样式三   拿交易中间层返回的物料给推荐
        // 请求商品列表
        const cccFeedback = await dispatch('productDetail/Recommend/get_ccc_feed_back', null, { root: true })
        const listStatus = await dispatch('compose_add_bag_list_data', { mainCouponItem })
        if(!listStatus) return false
        const addBagRecData = state.add_bag_rec_data
        const is_paid_user = rootGetters['productDetail/common/is_paid_user'] || ''
        const [AddBagRecDrawerInstance, config] = await Promise.all([
          import('public/src/pages/goods_detail_v2/innerComponents/recommend/AddBagRec/main.js').then(res => res.default),
          new Promise((resolve) => {
            const language = rootGetters['productDetail/common/language_v2']
            const productItemsLanguage = rootGetters['productDetail/common/product_items_language']
            const { itemConfig } = useItemConfig({
              name: 'DetailRecommendDrawerPic2',
              type: EItemConfigType.Pic2,
              mergeConfig: {
                useOwnClickAddBagAnalysis: true,
                showFeedback: !!cccFeedback, // 展示负反馈
              }
            })
            const productBffDependData = getters.product_bff_depend_data
            // 请求推荐的方法
            const integrateFetchRecommend = async (params) => {
              const recommendData = await dispatch('integrate_add_bag_recommend_list', params)
              const { oftenBoughtWithRecProducts = [], hasNextPage = '0', products = [], num = 0 } = recommendData?.info || {}
              // BFF 商卡数据转换
              const product_list = params.sence === 'coupon' ? products : oftenBoughtWithRecProducts
              const { goodsTransformationProductsWithSetup } = useProductCardUtils(productBffDependData)
              const list = goodsTransformationProductsWithSetup(product_list) || []
              return {
                list,
                hasNextPage,
                num
              }
            }
            const isHitComplianceMode = rootGetters['productDetail/common/is_hit_compliance_mode']
            let config = {
              language,
              productItemsLanguage,
              addBagRecData,
              clearRepeatArr: recommendExposureListInstance.getList(),
              integrateFetchRecommend,
              isHitComplianceMode,
              addBagRecommendConfig,
              resetCountFn,
              cbSetGoodsDetailScrollFn,
              update_need_scroll_review_ymal,
              mainCouponItem,
              couponInfo: {
                goods_id,
                cat_id,
                mainCouponCode: mainCouponItem?.couponCode || '',
              },
              inventoryInfo,
              fsAbt: {
                addbagsuccess
              },
              itemConfigPic2: { ...itemConfig.value },
              cccFeedback,
              isPaidUser: is_paid_user,
              recommendConfig: {
                goods_id,
                cat_id,
                maxNum: 500,
                include_tsp_id: mainCouponItem?.recommendInfo?.directTag || '',
                exclude_tsp_id: mainCouponItem?.recommendInfo?.returnTag || '',
              }
            }
            resolve(config)
          })
        ])
        if (!config) return false
        AddBagRecDrawerInstance.open(config)
        return true
      }
    },
    // 打开加车弹窗推荐数据组装
    async compose_add_bag_list_data({ state, rootGetters, commit, getters }, { mainCouponItem = null }) {
      const _withCCCFeedback = (list = []) => {
        const cccFeedback = state.ccc_feed_back
        if (!list?.length || !cccFeedback) return list
        return list.map(item => {
          return {
            ...item,
            cccFeedback,
          }
        })
      }
      const productBffDependData = getters.product_bff_depend_data
      const { goodsTransformationProductsWithSetup } = useProductCardUtils(productBffDependData)

      const language = rootGetters['productDetail/common/language_v2'] || {}
      const couponData = state.add_bag_rec_coupon_list_data // 优惠券凑单推荐数据
      const ofbwCatData = state.ofbw_cat_data
      const ofbwListData = state.ofbw_list_data
      const addBagRecData = []
      if(ofbwListData?.length) {

        const listData = goodsTransformationProductsWithSetup(ofbwListData) || []

        addBagRecData.push({
          item_name: language.SHEIN_KEY_PWA_32272 || 'all',
          item_id: 'all',
          firstPageFetch: true,
          list: _withCCCFeedback(listData)
        })
      } else {
        return false
      }
      if((couponData?.length || 0) > 10 && mainCouponItem) {
        const apply_for = mainCouponItem?.apply_for || 0
        const thresholds = mainCouponItem?.thresholds || []
        const isGoodsCoupon = [1, 2, 3, 7, 8].includes(+apply_for) // 商品券
        const isShipCoupon = [5, 6, 9].includes(+apply_for)
        let item_name = language.SHEIN_KEY_PWA_32276 || 'Coupon Discount'
        if(isGoodsCoupon && thresholds?.[0]?.couponTitle) {
          item_name = template(`<span class="font-orange">${thresholds[0].couponTitle}</span>`, `<span class="font-orange">${thresholds[0].thresholdPrice}</span>`, language.SHEIN_KEY_PWA_32274 || ' {0} OFF Over {1}')
        } else if(isShipCoupon) {
          item_name = language.SHEIN_KEY_PWA_32275 || 'Freeshipping Discount'
        }

        const couponList = goodsTransformationProductsWithSetup(couponData)
        addBagRecData.push({
          item_name: item_name,
          item_id: 'coupon',
          firstPageFetch: true,
          list: _withCCCFeedback(couponList)
        })
      }
      if((ofbwCatData.length || 0) > 5) {
        let catData = ofbwCatData
        catData = catData.slice(1, 9)
        catData.forEach(item => {
          addBagRecData.push({
            list: [],
            item_name: item.cat_name,
            item_id: String(item.cat_id),
          })
        })
      }
      commit('update_add_bag_rec_data', addBagRecData)
      return true
    },
    async fetch_rec_data_actions({ state, getters, rootGetters, commit, dispatch }) {
      const addBagRecommendConfig = getters.add_bag_recommend_config
      const goods_id = rootGetters['productDetail/common/goods_id']
      const addOnitemGoodsId = state.add_on_item_goods_id
      const isSameGoods = goods_id === addOnitemGoodsId
      const { addbagRecStyleOne, addbagRecStyleFour } = addBagRecommendConfig
      let fetchRecDataPromise = null
      if(addbagRecStyleOne){ // 只有样式一
        fetchRecDataPromise = Promise.all([
          dispatch('get_bff_coupon_info', { addbagRecStyleOne: true })
        ])
      } else if(addbagRecStyleFour) { // 样式四
        const outfits_info = rootGetters['productDetail/MainPicture/outfits_info']
        const isHasOutfits = outfits_info.isHasOutfits && !!outfits_info.gtlInfo
        if(isHasOutfits){
          commit('update_fetch_rec_data_promise_status', Promise.resolve('success'))
        }else{
          commit('update_fetch_rec_data_promise_status', Promise.resolve('failure'))
        }
        return
      } else { // 样式二三
        fetchRecDataPromise = Promise.all([
          dispatch('get_bff_coupon_info', { addbagRecStyleOne: false }),
          dispatch('fetch_add_bag_ofbw_list_data', { isSameGoods })
        ])
      }
      commit('update_fetch_rec_data_promise_status', fetchRecDataPromise)
      const fiveSecondLimit = new Promise((resolve) => {  
        // 超过5秒fetchRecDataPromise 还没返回，则直接不弹窗了
        setTimeout(()=>{ resolve('failure') }, 5000)
      }) 
      const res = await Promise.race([fiveSecondLimit, fetchRecDataPromise])
      if(res === 'failure'){
        commit('update_fetch_rec_data_promise_status', Promise.resolve('failure'))
      }
    },
    // 请求交易中间层优惠券信息
    async get_bff_coupon_info({ commit, rootGetters, dispatch }, { addbagRecStyleOne }) {
      const { currency, appLanguage } = window?.gbCommonInfo || {}
      const mainCode = getQueryString({ key: 'mainCode' }) || ''
      const mainCouponCode = mainCode || rootGetters['newProductDetail/common/mainCouponCode']
      const secondaryCoupon = rootGetters['newProductDetail/common/secondaryCoupon']
      const subCouponCodes = secondaryCoupon.map(item=>item.coupon).join(',')
      if(!mainCouponCode && !subCouponCodes) return false
      try {
        const bffCouponInfo = await schttp({
          method: 'POST',
          url: DETAIL_API_LOCATION.COUPON_ADDITEMINFO,
          data: {
            activityFrom: 'detail_rec_coupon_add',
            couponCode: mainCouponCode,
            curCouponCode: mainCouponCode,
            sort: 1,
            subCouponCodes,
          },
          headers: {
            'AppCurrency': currency,
            'AppLanguage': appLanguage
          },
          useBffApi: 1,
        })
        const couponInfos = bffCouponInfo?.info?.newPromotionPopupInfo?.couponInfos || []
        const mainCouponItem = couponInfos.find(item=>item.meetHigh == 0)
        if(bffCouponInfo.code !== '0' || !mainCouponItem) { // 接口请求错误，没有不满足门槛的券
          commit('update_bff_coupon_info', null)
          return false
        } else {
          commit('update_bff_coupon_info', couponInfos)
          if(!addbagRecStyleOne) { // 拿交易中间层的物料请求推荐
            await dispatch('fetch_add_bag_coupon_list_data', { mainCouponItem })
          }
          return true
        }
      } catch (error) {
        return false
      }
    },
    // 请求优惠券凑单推荐接口
    async fetch_add_bag_coupon_list_data({ rootGetters, commit, dispatch }, { mainCouponItem }) {
      const recommendInfo = mainCouponItem?.recommendInfo || {}
      const goods_id = rootGetters['productDetail/common/goods_id'] || ''
      const params = {
        main_goods_id: goods_id,
        cate_ids: '', // 设置空字符
        entranceScene: 'productDetails',
        page: '1', // 页码
        stock_enough: '1', // 是否进行库存过滤
        limit: '40', // 每页数量
        include_tsp_id: recommendInfo?.directTag || '',
        exclude_tsp_id: recommendInfo?.returnTag || '',
        goods_price: '',
        pageSceneBizCode: 'CartReconCollect',
        querySource: 'qSProductDetail'
      }
      const recommendsData = await dispatch('integrate_add_bag_recommend_list', { requestParams: params, sence: 'coupon' })
      const couponListData = recommendsData?.info?.products || []
      commit('update_add_bag_rec_coupon_list_data', couponListData)
      return recommendsData
    },
    async fetch_add_bag_ofbw_list_data({ dispatch, commit, state, rootGetters }, { isSameGoods }) {
      const curOfbwListData = state.ofbw_list_data
      if(isSameGoods && curOfbwListData?.length) {
        return curOfbwListData
      }
      const goodsId = rootGetters['productDetail/common/goods_id'] || ''
      const cateId = rootGetters['productDetail/common/cat_id'] || ''
      const params = {
        cateId,
        goodsId,
        filterCateId: '',
        pageQueryParam: {
          page: 1,
          limit: 40,
          queryType: '3' // 查询类型 1-similarItem 2-组合购 3-oftenBoughtWith 4-quickShip推荐
        }
      }
      const recommendsData = await dispatch('integrate_add_bag_recommend_list', { requestParams: params, sence: 'ofbw' })
      const ofbwListData = recommendsData?.info?.oftenBoughtWithRecProducts || []
      const ofbwListDataNum = ofbwListData.length || 0
      if(ofbwListDataNum < 20) {
        return false
      }
      const ofbwCatData =  recommendsData?.info?.categories || []
      commit('update_ofbw_list_data', ofbwListData)
      commit('update_ofbw_cat_data', ofbwCatData)
      commit('update_add_on_item_goods_id', goodsId)
      return recommendsData
    },
    // 整合优惠券凑单推荐和ofbw推荐
    async integrate_add_bag_recommend_list({}, params) {
      const { requestParams, sence = '' } = params
      const url = sence === 'coupon' ? DETAIL_API_LOCATION.CART_COLLECT_RECOMMEND_PRODUCT : DETAIL_API_LOCATION.RECOMMEND_COLLECT_SIMILAR
      const recommendsData = await schttp({
        url,
        method: sence === 'coupon' ? 'GET' : 'POST',
        data: requestParams,
        useBffApi: true
      })
      return recommendsData
    }
    //加车推荐弹窗逻辑end---
  },
  mutations: {
    update_show_outfit_alone(state, payload = false) {
      state.show_outfit_alone = payload
    },
    update_ccc_feed_back(state, payload) {
      state.ccc_feed_back = payload
    },
    update_find_similar_float_info(state, payload) {
      state.find_similar_float_info = payload
    },
    update_activity_combobuy_data(state, payload) {
      state.activity_combobuy_data = payload
    },
    update_find_similar_api_time_stamp(state, payload) {
      state.find_similar_api_time_stamp = payload
    },
    update_combo_buy_products(state, payload) {
      state.combo_buy_products = payload
    },
    update_combo_buy_price(state, payload) {
      state.combo_buy_price = getCalcBundlePrice(payload || {})
    },
    update_add_bag_rec_data(state, payload) {
      state.add_bag_rec_data = payload
    },
    update_bff_coupon_info(state, payload) {
      state.bff_coupon_info = payload
    },
    update_ofbw_cat_data(state, payload) {
      state.ofbw_cat_data = payload
    },
    update_ofbw_list_data(state, payload) {
      state.ofbw_list_data = payload
    },
    update_add_bag_rec_coupon_list_data(state, payload) {
      state.add_bag_rec_coupon_list_data = payload
    },
    update_fetch_rec_data_promise_status(state, payload) {
      state.fetch_rec_data_promise_status = payload
    },
    update_add_on_item_goods_id(state, payload) {
      state.add_on_item_goods_id = payload
    },

    update_ymal_exposed(state, payload) {
      state.is_ymal_exposed = payload
    },
    update_ymal_loaded(state, payload) {
      state.is_ymal_loaded = payload
    }
  },
}
